























import {
  defineComponent,
  PropType
} from '@nuxtjs/composition-api';
import type { configurableOption } from '../types';

export default defineComponent({
  name: 'Buttons',
  props: {
    option: {
      type: Object as PropType<configurableOption>,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    availableOptions: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['change']
})
